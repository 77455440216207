"use client"

import React, { useState } from "react";
import { addWaitList } from "../actions/waitListActions";
import { toast } from 'react-toastify';

const Center = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const response = await addWaitList(email);

    toast(response.message,{
      draggable: true,
      style: { zIndex: 9999 }, 
    });
    setEmail("");
  };

  return (
    <div className="center">
      <div className="logo">
        <img
          src="/assets/images/app-logo.webp"
          alt="QuickBrief Logo"
          className="logo-image"
        />
      </div>
      <div>
        <h1 className="title">Launching Very Soon</h1>
      </div>
      <div>
        <p className="description">
          We will be at your service in a very short time. Quick Brief will be
          with you whenever you want to improve yourself in your applications,
          experiments, UI/UX.
        </p>
      </div>
      <div>
        <form className="join-waitlist-bar" onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Enter Your E-Mail"
            className="join-waitlist-input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <button type="submit" className="join-waitlist-button">
            Join Waitlist
          </button>
        </form>
      </div>
    </div>
  );
};

export default Center;
