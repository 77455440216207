"use client"
import Center from "./components/Center";
import "./App.css";
import Snowfall from "react-snowfall";
import { ToastContainer } from "react-toastify";

export default function Home() {

  return (
    <>
      <Snowfall
        snowflakeCount={100}
        style={{ opacity: 0.5, zIndex: 999 }}
        radius={[0, 2]}
      />
      <div className="main-container">
        <Center />
        <img
          className="brief-image block sm:hidden"
          src="/assets/images/bottom-side-mobile.webp"
          alt="QuickBrief Bottom Side"
        />
        
        <img
          className="brief-image hidden sm:block"
          src="/assets/images/bottom-side-desktop.webp"
          alt="QuickBrief Bottom Side"
        />
      </div>
      <ToastContainer limit={3} />
    </>
  );
}
